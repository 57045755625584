
import { Options, Vue } from "vue-class-component";
import qs from "qs";
import moment from "moment";
import { Sleep } from "../middlewares/common";
import { envTypes } from "../middlewares/enum";

export default class UserCollectGood extends Vue {
  private show = false;
  private specimenForm = {
    idx: null,
    specName: "",
    specCode: "",
    address: "",
    character: "",
    packagingType: "",
    specification: "",
  };

  private openSpecimenInfo() {
    this.show = true;
  }

  // 编辑物品信息
  private editGood(good: any) {
    this.show = true;
    this.specimenForm = good;
  }

  //提交
  private submitForm() {
    if (
      !this.specimenForm.specName ||
      this.specimenForm.specName.length === 0
    ) {
      this.$toast.fail("请输入样本名称");
      return;
    }
    this.$emit("getGood", this.specimenForm);
    this.cancelForm();
  }

  private cancelForm() {
    this.specimenForm = {
      idx: null,
      specName: "",
      specCode: "",
      address: "",
      character: "",
      packagingType: "",
      specification: "",
    };
    this.show = false;
  }
}
