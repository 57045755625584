import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userColGood" }
const _hoisted_2 = { style: {"margin":"15px"} }
const _hoisted_3 = { style: {"margin":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom",
      closeable: "",
      "close-icon-position": "top-right",
      teleport: "#app",
      style: { width: '100%', height: '60%' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell_group, { title: "样本信息" }, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              modelValue: _ctx.specimenForm.specName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.specimenForm.specName) = $event)),
              label: "样本名称",
              placeholder: "请输入名称",
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.specimenForm.specCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.specimenForm.specCode) = $event)),
              label: "样本编号",
              placeholder: "请输入编号",
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.specimenForm.address,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.specimenForm.address) = $event)),
              label: "采样地点",
              placeholder: "请输入地点",
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.specimenForm.character,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.specimenForm.character) = $event)),
              label: "样品性状",
              placeholder: "请输入样品性状",
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.specimenForm.packagingType,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.specimenForm.packagingType) = $event)),
              label: "包装形式",
              placeholder: "请输入包装形式",
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.specimenForm.specification,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.specimenForm.specification) = $event)),
              label: "规格/型号",
              placeholder: "请输入规格/型号",
              "input-align": "right"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_van_button, {
            round: "",
            block: "",
            type: "primary",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("添加")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_van_button, {
            round: "",
            block: "",
            type: "default",
            onClick: _ctx.cancelForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}