
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import qs from "qs";
import SpecimenInfoRefs from "../../components/SpecimenInfoRefs.vue";
import { Sleep } from "../../middlewares/common";
import moment from "moment";
@Options({
  components: {
    SpecimenInfoRefs,
  },
})
export default class SampleCollect extends Vue {
  // @Action("setCollectGoods") private setCollectGoods: any;
  private showSpecPicker = false;
  private specPickerIdx = 0;
  private specTypes = [];

  public created() {
    (window as any).callJS2 = (msg: any) => {
      //和flutter一样的方法
      return this.callJS(msg);
    };
    console.log(localStorage.length);
    console.log(localStorage.getItem("userInfo"));
    console.log(this.$store.state.userInfo);
    if (this.$store.state.userInfo) {
      localStorage.setItem("token", this.$store.state.userInfo.token);
    }
    this.findSampleTypeList(this.$store.state.userInfo.orgId);
  }
  private callJS(message: any) {
    console.log(message);
    return "call js ok";
  }
  //获取组织机构列表
  private findSampleTypeList(val: any) {
    const checkOrgId = val;
    const url = "/api/lims/application/getSampleTypeByCheckOrgId";
    const data: any = { checkOrgId };
    this.axios
      .get(url, { params: data })
      .then((res: any) => {
        if (res.status.code !== 1) {
          //this.$alert(res.status.msg, '提示');
        } else {
          this.specTypes = res.data;
          // if (res.data) {
          //   res.data.forEach((item) => {
          //     //this.setSampleCode(item, item.code);
          //   });
          // }
        }
      })
      .catch((err) => {
        //this.$alert(err, '提示');
      });
  }

  private sampleForm: any = {
    sampleId: "",
    sampleName: "",
    sampleCode: "",
    sampleTypeId: "",
    sampleTypeCode: "",
    sampleTypeName: "",
    companyName: "",
    contactor: "",
    contactorTel: "",
    companyAddress: "",
    specimenList: [],
  };
  private addSpecimen() {
    const temp: any = this.$refs.specimenRefs;
    temp.openSpecimenInfo();
  }
  //添加物品回调
  private getAddedGood(good: any) {
    if (good.idx === null) {
      if (this.sampleForm.specimenList.length === 0) {
        good.idx = 0;
      } else {
        good.idx =
          this.sampleForm.specimenList[this.sampleForm.specimenList.length - 1]
            .idx + 1;
      }
      this.sampleForm.specimenList.push(good);
    } else {
      const temp = this.sampleForm.specimenList.findIndex(
        (p: any) => p.idx === good.idx
      );
      if (temp >= 0) {
        this.sampleForm.specimenList[temp] = good;
      }
    }
    //本地保存临时信息
    //this.setCollectGoods(this.sampleForm);
  }

  //修改采样物品信息
  private editGood(good: any, idx: number) {
    //打开居民弹框
    const temp: any = this.$refs.specimenRefs;
    temp.editGood(good);
  }
  //删除采样物品信息
  private deleteGood(good: any, idx: number) {
    this.$dialog
      .confirm({
        title: "确认",
        message: "确认要删除样本信息？",
      })
      .then(() => {
        this.sampleForm.specimenList.splice(idx, 1);
        //本地保存临时信息
        //this.setCollectGoods(this.sampleForm);
      })
      .catch(() => {
        console.log("cancel delete");
      });
  }
  private onConfirmSpecPicker(value: any) {
    //if(value)
    if (value[1]) {
      this.sampleForm.sampleTypeId = value[1].id;
      this.sampleForm.sampleTypeCode = value[0].code;
      this.sampleForm.sampleTypeName = value[1].name;
      this.sampleForm.sampleName = value[1].name;
    } else {
      this.sampleForm.sampleTypeId = value[0].id;
      this.sampleForm.sampleTypeCode = value[0].code;
      this.sampleForm.sampleTypeName = value[0].name;
      this.sampleForm.sampleName = value[0].name;
    }
    this.showSpecPicker = false;
  }
}
